/*****************************************************************************
 * UmanIT - Remove banner
 *****************************************************************************/

const btnsClose = document.querySelectorAll("[data-trigger='close-cgv']");

btnsClose.forEach(function(btnClose) {
  const banner =  btnClose.closest("[data-target='cgv']");
  btnClose.addEventListener("click", function(e) {
    e.preventDefault();
    banner.remove();
    document.cookie = "cookies_cgv=true";
  });
});
